import React from "react";
import Badge from 'react-bootstrap/Badge';

import PropTypes from "prop-types";

export const Tag = ({
  title,
}) => {
  return (
    <>
      <Badge bg="secondary" style={{ borderRadius: "12px", minWidth: "30px", minHeight: "24px", fontWeight: "400", paddingTop: "6px" }}>{title}</Badge>
    </>
  );
};

Tag.propTypes = {
  title: PropTypes.string,
};

Tag.defaultProps = {
  title: "",
};
