import React from "react";
import "./Tile.scss";
import { AreaTitle } from "../AreaTitle/AreaTitle";
import PropTypes from "prop-types";

export const Tile = ({
  children,
  propsTitle,
  addClass,
  onClick
}) => {
  return (
    <div className={`tile ${addClass}`} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      {propsTitle && <AreaTitle {...propsTitle} />}
      {children && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

Tile.propTypes = {
  children: PropTypes.node,
  propsTitle: PropTypes.object,
  addClass: PropTypes.string
};

Tile.defaultProps = {
};
