import "../../styles/Sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import useAuth from "../../hooks/useAuth";
import { Scroll } from "../../components/Scroll/Scroll";

function Sidebar({ isActive, action }) {
  const auth = useAuth();
  const location = useLocation();
  const thisLocation = location.pathname;
  const sidebarData = SidebarData;
  const perfilDelPacienteOpciones = sidebarData.perfilDelPaciente;
  const grupoFamiliarOpciones = sidebarData.grupoFamiliar;
  const prop = [perfilDelPacienteOpciones, grupoFamiliarOpciones];
  const isSuperAdmin = auth.getAdminData()?.is_superadmin;

  const showAcordeon = (i, id) => {
    const promise = prop[i].find((item) => {
      return item.id === id;
    });
    if (promise.a === false) {
      Object.defineProperty(promise, "a", {
        value: true,
        writable: true,
      });
    } else if (promise.a === true) {
      Object.defineProperty(promise, "a", {
        value: false,
        writable: true,
      });
    }
  };

  return (
    <>
      <div className={`sidebar ${isActive} `}>
        <Scroll addClass={`p-2`}>
          <ul className="mb-5">
            {SidebarData.inicio.map((item, index) => {
              return (
                <li key={index} className={item.cName} onClick={action}>
                  <NavLink activeClassName="activeItem" exact to={isSuperAdmin ? item.pathAdmin : item.pathUser}>
                    {item.icon}
                    <span>{item.title} </span>
                  </NavLink>
                </li>
              );
            })}
            {auth.typeUser === 2 && (
              <>
                <hr className="mt-3 mb-3" />
                <p className="m-0 mb-3 sidebarArea">Grupo familiar</p>
                {SidebarData.grupoFamiliar.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <NavLink
                        activeClassName={item.path ? "activeItem" : ""}
                        exact
                        to={item.path ? item.path : thisLocation}
                        className="d-flex justify-content-between"
                        onClick={
                          item.acordeon
                            ? () => {
                                showAcordeon(1, item.id);
                              }
                            : action
                        }
                      >
                        <span>
                          {item.icon} {item.title}
                        </span>
                        {item.acordeon ? (
                          <p
                            className={`m-0 ${item.a ? "rotate" : "norotate"}`}
                          >
                            {item.aIcon}
                          </p>
                        ) : (
                          <p className="m-0"></p>
                        )}
                      </NavLink>
                      {item.options ? (
                        <ul
                          className={`sidebar-acordeon__container ${
                            item.a ? "activeItem" : "inactive"
                          }`}
                        >
                          {item.options.map((option) => {
                            return (
                              <li
                                key={option.title}
                                className={item.cName}
                                onClick={action}
                              >
                                <NavLink to={option.path} activeClassName={"activeItem"}>
                                  <span>{option.title}</span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <ul></ul>
                      )}
                    </li>
                  );
                })}
                <hr className="mt-3 mb-3" />
                <p className="m-0 mb-3 sidebarArea">Perfil del paciente</p>
                {SidebarData.perfilDelPaciente.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <NavLink
                        activeClassName={item.path ? "activeItem" : ""}
                        className="d-flex justify-content-between"
                        exact
                        to={item.path ? item.path : thisLocation}
                        onClick={
                          item.acordeon
                            ? () => {
                                showAcordeon(0, item.id);
                              }
                            : action
                        }
                      >
                        <span className="d-flex align-items-center">
                          {item.icon}
                          {item.title}
                        </span>
                        {item.acordeon ? (
                          <p
                            className={`m-0 ${item.a ? "rotate" : "norotate"}`}
                          >
                            {item.aIcon}
                          </p>
                        ) : (
                          <p className="m-0"></p>
                        )}
                      </NavLink>
                      {item.options ? (
                        <ul
                          className={`sidebar-acordeon__container ${
                            item.a ? "active-acordeon" : "inactive"
                          }`}
                        >
                          {item.options.map((option) => {
                            return (
                              <li
                                key={option.title}
                                className={item.cName}
                                onClick={action}
                              >
                                <NavLink to={option.path} activeClassName={"activeItem"}>
                                  <span>{option.title}</span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <ul></ul>
                      )}
                    </li>
                  );
                })}
                <hr className="mt-3 mb-3" />
                <p className="m-0 mb-3 sidebarArea">Insituciones</p>
                {SidebarData.instituciones.map((item, index) => {
                  return (
                    <li key={index} className={item.cName} onClick={action}>
                      <NavLink activeClassName="activeItem" exact to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </>
            )}
            {auth.typeUser === 1 && (
              <>
                <hr />
                <p className="sidebar__title">Administrador</p>
                {SidebarData.admin.map((item, index) => {
                  return (
                    <li key={index} className={item.cName} onClick={action}>
                      <NavLink activeClassName="activeItem" exact to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </>
            )}
            {auth.typeUser === 1 && (
              <>
                {isSuperAdmin
                  ? SidebarData.superadmin.map((item, index) => {
                      return (
                        <li key={index} className={item.cName} onClick={action}>
                          <NavLink
                            activeClassName="activeItem"
                            exact
                            to={item.path}
                          >
                            {item.icon}
                            <span>{item.title}</span>
                          </NavLink>
                        </li>
                      );
                    })
                  : ""}
              </>
            )}
          </ul>
        </Scroll>
      </div>
    </>
  );
}

export default Sidebar;
