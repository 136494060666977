import React, { useCallback, useEffect, useState } from "react";
import usePatient from "../../../../hooks/usePatient";
import Loader from "../../../../components/Loader";
import { DataNotFound } from "../../../../components/DataNotFound/DataNotFound";
import Swal from "sweetalert2";
import { error } from "../../../../components/SwalAlertData";
import { Card } from "react-bootstrap";
import hceService from "../../../../services/hceServices/hceService";
import { typeHCE } from "../../../../constants/api.constants";

const  HCEGeneric = (props) => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const p = usePatient();
  const [data, setData] = useState([]);

  const getData = useCallback(
    (institution, type, id_patient) => {
      hceService(institution, type, id_patient, p.patient.id)
        .then((res) => {
          console.log('res', res)
          data.pop();
          if (res.length > 0) {
            res.map((d, i) => {
              iterateObject(d);
              setNotFound(false);
            });
          } else {
            setData([]);
            setNotFound(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(error("Hubo un error al solicitar datos"));
          setLoading(false);
        });
    },
    [data]
  );

  const iterateObject = (info) => {
    let patientData = [];
    Object.entries(info).forEach(([key, value], i, obj) => {
      if (typeof value === "string" || typeof value === "number") {
        patientData.push(`${key}: ${value}`);
      }
      if (typeof value === "object") {
        Object.entries(value).forEach(([k, v]) => {
          patientData.push(`${k}: ${v}`);
        });
      }
      if (Object.is(obj.length - 1, i)) {
        setNewData(patientData);
      }
    });
  };

  const setNewData = (enteredInfo) => {
    data.push(enteredInfo);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData(p.patientInstitution, props.type, p.idPatient);
  }, [p.patientInstitution, p.idPatient, props]);

  return (
    <div className="in">
      {loading ? (
        <Loader isActive={loading}></Loader>
      ) : (
        <>
          {data.map((d, i) => {
            return (
              <Card key={i} className="mb-3 shadow-sm">
                <Card.Header>
                </Card.Header>
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    {d.map((itemData, i) => {
                      return <p key={i}>{itemData}</p>;
                    })}
                  </blockquote>
                </Card.Body>
              </Card>
            );
          })}
          {notFound && <DataNotFound text={typeHCE[props.type]} />}
        </>
      )}
    </div>
  );
}

export default HCEGeneric;
