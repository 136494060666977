import { AUTH_HEADER } from "../constants/api.constants";
import { NOMIVAC_VACCINATION } from "../constants/nomivac.constants";
import { post } from "./httpServices";

export async function vaccinationService(data) {
    try { 
        const response = await post(NOMIVAC_VACCINATION(data), AUTH_HEADER());
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.error("Error al realizar la solicitud: ", err);
        throw err; 
    }
}