import { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Profile from "../../components/Profile/Profile";
import DatosPaciente from "./components/DatosPaciente";
import * as FaIcon from "react-icons/fa";
import * as MdIcon from "react-icons/md";
import Usualinstitution from "../../components/UsualInstitution/UsualInstitution";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { BasicCard } from "../../components/BasicCard/BasicCard";

export default function PerfilPaciente() {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showSelectorInst, setShowSelectorInst] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSelectorInst = () => {
    setShowSelectorInst(false);
    history.push("/usuario");
  };

  const verHistoriaClinica = () => {
    history.push("/usuario/historia-clinica/alergias");
  };

  useEffect(() => {
    if (location.search.includes("complete=false")) {
      setShowSelectorInst(true);
    }
  }, [location]);

  return (
    <>
      <BasicCard
        header={
          <AreaTitle
            addClass="p-3"
            title="Perfil del paciente"
            icon={<MdIcon.MdPerson />}
            rightComponents={[
              <div className="my-tooltip">
                <button
                  className="btn text-secondary btn-icon"
                  onClick={() => {
                    verHistoriaClinica();
                  }}
                >
                  <MdIcon.MdFolderShared style={{ fontSize: "1.5rem" }} />
                </button>
                <span className="tiptext">Ver historia clínica</span>
              </div>,
              <div className="my-tooltip">
                <button
                  className="btn text-secondary btn-icon"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <FaIcon.FaUserEdit style={{ fontSize: "1.5rem" }} />
                </button>
                <span className="tiptext">Editar</span>
              </div>,
            ]}
          />
        }
      >
        <DatosPaciente></DatosPaciente>
        {show && (
          <Profile type={"patient"} show={show} handleClose={handleClose} />
        )}
        {showSelectorInst && (
          <Usualinstitution
            type={"patient"}
            show={showSelectorInst}
            handleClose={handleCloseSelectorInst}
          />
        )}
      </BasicCard>
    </>
  );
}
