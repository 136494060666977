import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SidebarData } from "../../components/Sidebar/SidebarData";
import AdminMessagesRouter from "./AdminMessagesRouter";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { MdMessage } from "react-icons/md";

export default function AdminMessages() {
  const data = SidebarData.admin.find((d) => d.id === 15);
  const routes = data.options;

  return (
    <BasicCard
      header={
        <AreaTitle
          title="Mensajería"
          addClass="p-3"
          icon={<MdMessage />}
        />
      }
    >
      <Row>
        <Col className="switch-container p-3">
          {routes.map((route) => {
            return (
              <NavLink
                key={route.path}
                className="me-2"
                activeClassName="active-switch"
                to={route.path}
              >
                {route.title}
              </NavLink>
            );
          })}
        </Col>
      </Row>
      <AdminMessagesRouter></AdminMessagesRouter>
    </BasicCard>
  );
}
