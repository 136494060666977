import FilesDragAndDrop from "./DragAndDrop/drag_and_drop";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import * as MdIcon from "react-icons/md";

export default function Estudios() {
  return (
    <BasicCard
      header={
        <AreaTitle
          addClass="p-3"
          title="Estudios"
          icon={<MdIcon.MdTextSnippet />}
        />
      }
    >
      <FilesDragAndDrop />
    </BasicCard>
  );
}
