import PropTypes from "prop-types";
import noDataImage from "../../assets/images/empty.png";

/**
 * Componente que muestra un mensaje de alerta cuando no se encuentran datos.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.text - El texto que se mostrará en el mensaje de alerta.
 *
 * @returns {JSX.Element} Un elemento JSX que representa un mensaje de alerta.
 */
export const DataNotFound = ({ text }) => {
  return (
    <>
      <div
        className="w-100 h-100 d-flex justify-content-center align-items-center flex-column"
      >
        <img className="imageSizeEmpty" src={noDataImage} alt="No se encontraron datos" />
        <p className="text-center text-secondary">No se encontraron datos sobre {text}.</p>
      </div>
    </>
  );
};

DataNotFound.propTypes = {
  text: PropTypes.string,
};

DataNotFound.defaultProps = {};

