import React, { useMemo } from "react";

import PropTypes from 'prop-types';

import './Scroll.scss';

export const Scroll = ({ children, scrollDirection, addClass }) => {

	const scrollClass = useMemo(() => {
    let result;
    switch (scrollDirection) {
      case 'vertical':
        result = 'overflow-y-auto';
        break;
      case 'horizontal':
        result = 'overflow-x-auto';
        break;
      default:
        result = 'overflow-auto';
    }
    return result;
	}, [scrollDirection]);

  return (
    <div style={{ padding: '4px', overflow: 'hidden' }} className="h-100">
      <div className={`w-100 h-100 Scrollbar ${scrollClass} ${addClass}`}>
        {children}
      </div>
    </div>
  );
};

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
  scrollDirection: PropTypes.oneOf(['vertical', 'horizontal']),
  addClass: PropTypes.string,
};

Scroll.defaultProps = {
  scrollDirection: 'vertical',
  addClass: '',
};
