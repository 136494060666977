import { Col, Row } from "react-bootstrap";
import * as MdIcon from 'react-icons/md'

function Vaccination({ vaccinationData }) {

    const date = new Date(vaccinationData.fechaAplicacion).toLocaleDateString('es-ES')


    return (
        <Row className={`appointment-card`}>
            <Col xs={12} lg={2} className="border-end d-flex justify-content-center align-items-center mb-2 mb-md-0">
                <MdIcon.MdOutlineVaccines className="text-secondary" style={{ fontSize: '3rem' }} />
            </Col>
            <Col xs={10}>
            <Row>
                <Col xs={12} lg={10} className="d-flex">
                    <span className="text-secondary me-1">Vacuna:</span>
                    <h5><strong>{vaccinationData.sniVacunaNombre}</strong></h5>
                </Col>
                <Col>
                    <span className="text-secondary me-1">Fecha:</span>
                    <span>{date}</span>
                </Col>
                <Col>
                    <span className="text-secondary me-1">Lote:</span>
                    <span>{vaccinationData.lote}</span>
                </Col>
                <Col>
                    <span className="text-secondary me-1">Dosis:</span>
                    <span>{vaccinationData.sniDosisOrden}º</span>
                </Col>

                <Col xs={12} className="mt-4">
                    <span className="text-secondary me-1">Lugar:</span>
                    <span>{vaccinationData.origenNombre}</span>
                </Col>
                <Col>
                    <span className="text-secondary me-1">Localidad:</span>
                    <span>{vaccinationData.origenLocalidad}</span>
                </Col>
                <Col>
                    <span className="text-secondary me-1">Provincia:</span>
                    <span>{vaccinationData.origenProvincia}</span>
                </Col>
            </Row>
            </Col>
        </Row>
    )
}

export default Vaccination;
