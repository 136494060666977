import RegisterForm from "../../components/RegisterForm/RegisterForm";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";

function Register() {
  const number = Math.floor(Math.random() * (5 - 0)) + 1;

  return (
    <div className={`bg-container bg${number}`}>
      <BasicCard
        addClass="z-index-1 cont"
        removeDivider={true}
        header={<AreaTitle title="Registrarse" addClass="p-3" />}
      >
        <RegisterForm formType={"user"}></RegisterForm>
      </BasicCard>
    </div>
  );
}

export default Register;
