import React, { useState } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { Button, Col } from 'react-bootstrap';
import * as MdIcon from 'react-icons/md';


const MedicalCertificatePDF = ({ appointments, institution, to, from}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePDF = async () => {
    setIsGenerating(true);

    try {
      const pdfDoc = await PDFDocument.create();

      const fontSize = 10;
      const margin = 50;
      const lineHeight = fontSize + 4; // Espacio entre líneas de texto
      let isFirstPage = true;

      let page = pdfDoc.addPage();
      let { width, height } = page.getSize();
      let yOffset = height - margin;

      // Función para agregar encabezado
      const addHeader = () => {
        if (isFirstPage) {
          page.drawText('PORTAL DEL PACIENTE | LA RIOJA', {
            x: margin,
            y: yOffset,
            size: 14,
            color: rgb(233 / 255, 0 / 255, 45 / 255), // #E9002D en formato RGB
          });
          yOffset -= 30;

          page.drawText(`Certificado de Atención Médica`, {
            x: margin,
            y: yOffset,
            size: 16,
            color: rgb(0, 0, 0),
          });
          yOffset -= 20;

          page.drawText(`${institution}`, {
            x: margin,
            y: yOffset,
            size: 16,
            color: rgb(0, 0, 0),
          });
          yOffset -= 40;

          // Marcar que el encabezado ya fue agregado
          isFirstPage = false;
        }
      };

      addHeader(); // Agregar encabezado en la primera página

      // Ordenar los turnos del más nuevo al más viejo
      const sortedAppointments = [...appointments].sort((a, b) => {
        const dateA = new Date(a.attentionDate.year, a.attentionDate.month - 1, a.attentionDate.day);
        const dateB = new Date(b.attentionDate.year, b.attentionDate.month - 1, b.attentionDate.day);
        return dateB - dateA;
      });

      sortedAppointments.forEach((appointment) => {
        const appointmentInfo = [
          `Fecha de atención: ${appointment.attentionDate.day}/${appointment.attentionDate.month}/${appointment.attentionDate.year}`,
          `Especialidad: ${appointment.speciality.snomed.pt}`,
          `Paciente: ${appointment.patient.lastName}, ${appointment.patient.firstName}`,
          `DNI: ${appointment.patient.identificationNumber}`,
          `Fecha de nacimiento: ${appointment.patient.birthDate.day}/${appointment.patient.birthDate.month}/${appointment.patient.birthDate.year}`,
          `Médico: ${appointment.responsibleDoctor.lastName}, ${appointment.responsibleDoctor.firstName}`,
          `Matrícula: ${appointment.responsibleDoctor.licenceNumber}`,
          `Diagnóstico: ${appointment.diagnoses.main.pt}`,
          `Hora de atención: ${appointment.attentionDateWithTime.time.hours}:${appointment.attentionDateWithTime.time.minutes.toString().padStart(2, '0')}`,
          '',
          '___________________________________________________________',
        ];

        appointmentInfo.forEach((line) => {
          // Si el espacio en la página no es suficiente, agregar una nueva página
          if (yOffset - lineHeight < margin) {
            page = pdfDoc.addPage();
            yOffset = height - margin;
          }

          // Dibujar la línea de texto
          page.drawText(line, {
            x: margin,
            y: yOffset,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
          yOffset -= lineHeight;
        });

        // Espaciado adicional entre bloques de citas
        yOffset -= lineHeight;
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'certificado_medico.pdf';
      link.click();
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    } finally {
      setIsGenerating(false);
    }
  };
  
  return (
    <Col xs={12} lg={6} className="border boder-secundary rounded p-4 mt-4 mb-2">

      {/* <Col className=''> */}
        <p>Certificado Médico: {institution?.description} </p>
        {from && to && <p>{from.toLocaleDateString()} - {to.toLocaleDateString()}</p>}
      {/* </Col> */}

      <Col xs={12} className='d-flex justify-content-end'>
        <Button
          type="button"
          variant="primary"
          onClick={generatePDF}
          disabled={isGenerating}
          className="btn"
          aria-busy={isGenerating}
        >
          <MdIcon.MdDownload className='me-2'></MdIcon.MdDownload>
          {isGenerating ? 'Generando PDF...' : 'Descargar Certificado Médico'}
        </Button>
      </Col>
    </Col>
  );
};

export default MedicalCertificatePDF