import { Row } from "react-bootstrap";
import { AltaUsers } from "./charts/AltaUsers/AltaUsers";
import { ActiveUsers } from "./charts/ActiveUsers/ActiveUsers";
import { Turnos } from "./charts/Turnos/Turnos";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { MdAreaChart } from "react-icons/md";
import { Accordion } from "react-bootstrap";
import { Studies } from "./charts/Studies/Studies";
import { TurnosHSI } from "./charts/TurnosHSI/TurnosHSI";
import { HCEChart } from "./charts/HCEChart/HCEChart";
import { useState } from "react";
import { SumarChart } from "./charts/SumarChart/SumarChart";

export default function AdminDashboard() {

  const [active, setActive ] = useState('');

  return (
    <BasicCard
      header={
        <AreaTitle title="Indicadores" addClass="p-3" icon={<MdAreaChart />} />
      }
    >

      <Accordion className="border boder-secondary" onSelect={() => setActive('altaUsers')}>
        <Accordion.Header>
          Alta de usuarios
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
           {active === 'altaUsers' && <AltaUsers />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('activeUser')}>
        <Accordion.Header>
          Usuarios Activos
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'activeUser' && <ActiveUsers />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('turnos')}>
        <Accordion.Header>
         Turnos solicitados por email
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'turnos' && <Turnos />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('turnosHSI')}>
        <Accordion.Header>
         Turnos solicitados por HSI
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'turnosHSI' && <TurnosHSI />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('studies')}>
        <Accordion.Header>
         Estudios cargados
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'studies' && <Studies />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('HCEChart')}>
        <Accordion.Header>
         Consultas de Historia Clínica Electrónica
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'HCEChart' && <HCEChart />}
          </Row>
        </Accordion.Body>
      </Accordion>
      <Accordion className="border boder-secondary" onSelect={() => setActive('SumarChart')}>
        <Accordion.Header>
         Prestaciones Sumar
        </Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">
          {active === 'SumarChart' && <SumarChart />}
          </Row>
        </Accordion.Body>
      </Accordion>
    </BasicCard>
  );
}
