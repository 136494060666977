import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../NotFound';
import HCEGeneric from './pages/HCEGeneric';

export default function HCDRouter() {
    return (
        <Switch>
            <Route path='/usuario/historia-clinica/signos-vitales' render={(props) => <HCEGeneric {...props} type="vitalSigns" />} />
            <Route path='/usuario/historia-clinica/alergias' render={(props) => <HCEGeneric {...props} type="allergies" />} />
            <Route path='/usuario/historia-clinica/antecedentes-familiares' render={(props) => <HCEGeneric {...props} type="familyHistories" />} />
            <Route path='/usuario/historia-clinica/antecedentes-personales' render={(props) => <HCEGeneric {...props} type="personalHistories" />} />
            <Route path='/usuario/historia-clinica/datos-antropometricos' render={(props) => <HCEGeneric {...props} type="anthropometricData" />} />
            <Route path='/usuario/historia-clinica/datos-cronicos' render={(props) => <HCEGeneric {...props} type="chronic" />} />
            <Route path='/usuario/historia-clinica/hospitalizacion' render={(props) => <HCEGeneric {...props} type="hospitalization" />} />
            <Route path='/usuario/historia-clinica/inmunizacion' render={(props) => <HCEGeneric {...props} type="immunizations" />} />
            <Route path='/usuario/historia-clinica/medicacion' render={(props) => <HCEGeneric {...props} type="medications" />} />
            <Route path='/usuario/historia-clinica/problemas-activos' render={(props) => <HCEGeneric {...props} type="activeProblems" />} />
            <Route path='/usuario/historia-clinica/problemas-resueltos' render={(props) => <HCEGeneric {...props} type="solvedProblems" />} />
            <Route path='/usuario/historia-clinica/registros-dentales' render={(props) => <HCEGeneric {...props} type="toothRecords" />} />
            <Route path='/usuario/historia-clinica/404' component={NotFound} />
            <Route path='/usuario/historia-clinica/*'><Redirect to='/404' /></Route>
        </Switch>
    )
}
