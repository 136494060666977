import { Col, Row } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { getStudiesIndicators, getStudiesMonthlyIndicators } from "../../../../services/dashboardService";
import Swal from 'sweetalert2';
import { error } from "../../../../components/SwalAlertData";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const Studies = () => {
    const today = new Date();
    const lastYear = new Date(today);
    lastYear.setUTCFullYear(today.getUTCFullYear() - 1);
    const defaultFrom = lastYear.toISOString().split('T')[0];
    const defaultTo = today.toISOString().split('T')[0];

    const [fromDate, setFromDate] = useState(defaultFrom);
    const [toDate, setToDate] = useState(defaultTo);
    const [dataStudies, setDataStudies] = useState([]);
    const [dataStudiesMonthly, setDataStudiesMonthly] = useState([]);
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const [labels, setLabels] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [totalFiltered, setTotalFiltered] = useState(0);

    useEffect(() => {
        handleDataStudies();
        handleDataMonthlyStudies();
    }, []);

    const handleDataStudies = useCallback(() => {
        getStudiesIndicators()
            .then(res => setDataStudies([res.estudios_cargados]))
            .catch(err => {
                console.error(err);
                Swal.fire(error('Error al cargar datos'));
            });
    }, []);

    const handleDataMonthlyStudies = useCallback(() => {
        getStudiesMonthlyIndicators()
            .then(res => {
                if (res.evolucion_estudios_mensual.length > 0) {
                    setDataStudiesMonthly(res.evolucion_estudios_mensual);
                }
            })
            .catch(err => {
                console.error(err);
                Swal.fire(error('Error al cargar datos'));
            });
    }, []);

    const onFromChange = (event) => {
        const newFromDate = event.target.value;
        setFromDate(newFromDate);
        if (newFromDate > toDate) {
            setToDate(newFromDate);
        }
    };

    const onToChange = (event) => {
        setToDate(event.target.value);
    };


    const generarMesesEnRango = (desde, hasta) => {
        let meses = [];
        let fecha = new Date(desde.getFullYear(), desde.getMonth(), 1);
        let fin = new Date(hasta.getFullYear(), hasta.getMonth(), 1);
    
        while (fecha <= fin) {
            const mesStr = fecha.toISOString().slice(0, 7);
            meses.push(mesStr);
            fecha.setMonth(fecha.getMonth() + 1);
        }
        return meses;
    };

    const filterDataByDates = (from, to, data) => {
        const fromDateObj = new Date(`${from}T00:00:00`);
        const toDateObj = new Date(`${to}T00:00:00`);
        const mesesEnRango = generarMesesEnRango(fromDateObj, toDateObj);
        const dataMap = Object.fromEntries(data.map(item => [item.mes, item.cantidad]));
        const datosCompletos = mesesEnRango.map(mes => ({
            mes,
            cantidad: dataMap[mes] || 0
        }));
        setLabels(datosCompletos.map(item => {
            const [year, month] = item.mes.split("-");
            return `${months[parseInt(month, 10) - 1]} ${year}`;
        }));
        setQuantity(datosCompletos.map(item => item.cantidad));
        setTotalFiltered(datosCompletos.map(item => item.cantidad).reduce((acc, val) => acc + val, 0))
    };

    useEffect(() => {
        if (fromDate && toDate && dataStudiesMonthly.length > 0) {
            filterDataByDates(fromDate, toDate, dataStudiesMonthly);
        }
    }, [fromDate, toDate, dataStudiesMonthly]);

    const data = {
        labels,
        datasets: [
            {
                label: "Cantidad mensual",
                data: quantity,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    stepSize: 1,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: { display: true },
            title: { display: true, text: "Estudios cargados mensualmente" },
        },
    };

    return (
        <Row>
            <Col xs={12}>

                <h5>Estudios cargados</h5>
                <div className="mb-3 d-flex">
                    <div>
                        <label htmlFor="desde">Desde:</label>
                        <input
                            className="mx-2 border border-secondary rounded p-2"
                            type="date"
                            name='from'
                            value={fromDate || ""}
                            onChange={onFromChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="hasta">Hasta:</label>
                        <input
                            className="mx-2 border border-secondary rounded p-2"
                            type="date"
                            name='to'
                            value={toDate || ""}
                            onChange={onToChange}
                        />
                    </div>
                </div>
            </Col>
            <Col lg={6} className='my-3'>
                <div className="p-3 border border-secondary rounded" style={{ maxHeight: '400px' }}>
                    <Bar data={data} height={70} width={100} options={options} />
                </div>
            </Col>
            <Col lg={6}>
                <div className="d-flex border border-dark-subtle rounded justify-content-between p-4 mt-4">
                    <h5 className="mb-0">Cargados desde {fromDate} hasta {toDate}:</h5>
                    <h5 className="mb-0 count-number text-success">{totalFiltered}</h5>
                </div>
                <div className="d-flex border border-dark-subtle rounded justify-content-between p-4 mt-4">
                    <h5 className="mb-0">Total estudios cargados en el protal:</h5>
                    <h5 className="mb-0 count-number text-success">{dataStudies}</h5>
                </div>
            </Col>
        </Row>
    );
};
