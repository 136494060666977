import React, { useCallback, useState } from "react";
import { MdOutlineNotifications, MdOutlineNotificationsActive  } from "react-icons/md";
import usePatient from "../../hooks/usePatient";
import { setMessageRead } from "../../services/messagesServices";
import { ModalMensaje } from "./ModalMensaje/ModalMensaje";
import { Tile } from "../../components/Tile/Tile";
import "./Mensaje.scss";
import { Truncate } from "../Truncate/Truncate";

export const Mensaje = (props) => {
  //Person
  const p = usePatient();
  const idPerson = p.patient.id;

  //Message
  const { asunto, from, mensaje, isRead, idMessage, action } = props;

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    action();
  };

  const handleShow = () => setShow(true);

  // Icon
  const [open, setOpen] = useState(false);

  const abrirMensaje = () => {
    handleShow();
    setOpen(true);
    if (!isRead) {
      read(idPerson, idMessage);
    }
  };

  const read = useCallback((person_id, message_id) => {
    setMessageRead(person_id, message_id)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Tile
        addClass={`ps-3 ${!isRead && 'tile-danger'}`}
        onClick={abrirMensaje}
        propsTitle={{
          addClass: "pb-0",
          title: asunto,
          addClassTitle: isRead ? `color-primary text-dark small mt-2 fw-bold` : `small mt-2 fw-bold`,
          icon: isRead ? <MdOutlineNotifications className="text-secondary" /> : <MdOutlineNotificationsActive className="text-danger" />,
        }}
      >
        <div className="mt-1 message">
          <p className="m-0"><Truncate text={mensaje} showTooltip={false} fontSize="12px"/> </p>
        </div>
      </Tile>
      <ModalMensaje
        asunto={asunto}
        mensaje={mensaje}
        from={from}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};
