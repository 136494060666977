import { Col, Row } from "react-bootstrap";
import * as MdIcon from "react-icons/md";
import * as FaIcon from "react-icons/fa";
import { useState } from "react";
import InstitutionMap from "../InstitutionMap/InstitutionMap";
import { Tile } from "../../../../../components/Tile/Tile";
import { SingleViewer } from "../../../../../components/SingleViewer/SingleViewer";
import { Tag } from "../../../../../components/Tag/Tag";

const InstitutionCard = ({ institution }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const institutionData = institution;

  return (
    <Tile
      addClass={`ps-3`}
    >
      <Row>
        <Col
          xs={12}
          md={2}
          className="border-end d-flex justify-content-center align-items-center mb-2 mb-md-0"
        >
          {institutionData.tipologia === "Hospital" && (
            <FaIcon.FaHospital style={{ fontSize: "3rem" }} />
          )}
          {institutionData.tipologia === "Transporte" && (
            <FaIcon.FaAmbulance style={{ fontSize: "3rem" }} />
          )}
          {institutionData.tipologia ===
            ('Centro de salud "A"' || 'Centro de salud "A"') && (
            <MdIcon.MdOutlineHealthAndSafety style={{ fontSize: "3rem" }} />
          )}
          {institutionData.tipologia === "Laboratorio" && (
            <MdIcon.MdOutlineScience style={{ fontSize: "3rem" }} />
          )}
        </Col>
        <Col
          xs={12}
          md={10}
          className="d-flex flex-column justify-content-between"
        >
          <div>
            <div className="mt-2">
              <h4 className="text-uppercase text-dark fw-bold" style={{ fontSize: "1.2rem" }}>{institutionData.name}</h4>
              <h6 className="text-uppercase text-dark" style={{ fontSize: "0.9rem" }}>
                {institutionData.tipologia} -{" "}
                {institutionData.categoria_tipologia}
              </h6>
            </div>
            {institutionData.services.length > 0 && (
              <>
                <hr></hr>
                <SingleViewer
                  labelAddClass="textSmaller"
                  valueAddClass="textSmaller text-dark"
                  verticalSeparation="mb-0"
                  horizontalSeparation="10em"
                  items={[
                    {
                      label: "Servicios:",
                      custom: (
                        <div className="d-flex flex-wrap gap-2">
                          {institutionData.services.map((item) => (
                            <>
                              <Tag title={item.name} />
                            </>
                          ))}
                        </div>
                      )
                    }
                  ]}
                />
              </>
            )}
            <hr></hr>
          </div>
          <div>
            <SingleViewer
              labelAddClass="textSmaller"
              valueAddClass="textSmaller text-dark"
              verticalSeparation="mb-2"
              horizontalSeparation="10em"
              items={[
                {
                  label: "Dirección:",
                  value: `${institutionData.domicilio || " - "}`,
                },
                {
                  label: "Departamento:",
                  value: `${institutionData.departamento || " - "}`,
                },
                {
                  label: "Localidad:",
                  value: `${institutionData.localidad || " - "}`,
                },
                {
                  label: "Telefono:",
                  value: `${institutionData.telefono || " - "}`,
                }
              ]}
            />
            <div className="d-flex justify-content-end" style={{ marginBottom: '0.4rem', marginRight: '0.4rem' }}>
              <button onClick={() => handleShow()} className='btn btn-danger'><MdIcon.MdLocationOn className='me-2' style={{ fontSize: '1.5rem' }} />Ver ubicación</button>
            </div>
          </div>
        </Col>
      </Row>
      {show && (
        <InstitutionMap
          institution={institution}
          show={show}
          handleClose={handleClose}
        />
      )}
    </Tile>
  );
};

export default InstitutionCard;
