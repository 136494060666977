import { HSI_CANCEL_APPOINTMENT, HSI_GET_AVAILABLE_APPOINTMENTS, HSI_GET_INSTITUTIONS, HSI_GET_MEDICAL_COVERAGE, HSI_GET_SPECIALTY, HSI_POST_APPOINTMENT, SEND_APPOINTMENT_MAIL } from "../constants/api-hsi.constants";
import { API_ENDPOINT_GET_USER_APPOINTMENTS, API_ENDPOINT_SEND_TURNO_MAIL, API_HEADER, AUTH_HEADER, UPDATE_HEADER } from "../constants/api.constants";
import { get, post, put } from "./httpServices";

export async function sendAppointmentEmailService(person_id, subject, body) {
  try {
    const searchParams = new URLSearchParams({
      person_id: person_id,
      subject: subject,
      body: body,
    });
    let query = searchParams.toString();
    const promise = await post(
      API_ENDPOINT_SEND_TURNO_MAIL(query),
      AUTH_HEADER()
    );
    return promise;
  } catch (err) {
    console.error(err);
  }
}

/**   -----   HSI    -----     */

export async function getInstitutionsHSI() {
  try {
    const promise = await get(HSI_GET_INSTITUTIONS, AUTH_HEADER());
    return promise;
  } catch (err) {
    console.error(err);
  }
}
export async function getSpecialtiesHSI() {
  try {
    const promise = await get(HSI_GET_SPECIALTY, API_HEADER());
    return promise;
  } catch (err) {
    console.error(err);
  }
}
export async function getCoverageHSI() {
  try {
    const promise = await get(HSI_GET_MEDICAL_COVERAGE, API_HEADER());
    return promise;
  } catch (err) {
    console.error(err);
  }
}

export async function getAvailableAppointmentsService(institution_id, specialty_id, coverage_id) {
  try {
    const promise = await get(HSI_GET_AVAILABLE_APPOINTMENTS(institution_id, specialty_id, coverage_id), AUTH_HEADER());
    return promise;
  } catch (err) {
    console.error(err);
  }
}

export async function postAppointment(body, institution_id, person_id) {
  try {
    const data = JSON.stringify(body);
    const promise = await post(
      HSI_POST_APPOINTMENT(institution_id, person_id),
      UPDATE_HEADER(),
      data
    );
    return promise;
  } catch (err) {
    console.error(err);
  }
}

export async function cancelAppointment(institution_id, appointment_id) {
  try {
    const promise = await put(
      HSI_CANCEL_APPOINTMENT(institution_id, appointment_id),
      AUTH_HEADER(),
    );
    return promise;
  } catch (err) {
    console.error(err);
  }
}

export async function getUserAppointmentsService(userData) {
  try {
    const searchParams = new URLSearchParams(userData);
    let query = searchParams.toString();
    const promise = await get(API_ENDPOINT_GET_USER_APPOINTMENTS(query), AUTH_HEADER());
    return promise;
  } catch (err) {
    console.error(err);
  }
}

export async function sendAppointmentHSIEmail(body) {
  try {
    const data = JSON.stringify(body);
    const promise = await post(SEND_APPOINTMENT_MAIL, UPDATE_HEADER(), data );
    return promise;
  } catch (err) {
    console.error(err);
  }
}
