import React from "react";
import { Modal, Button } from "react-bootstrap";

import { AreaTitle } from "../AreaTitle/AreaTitle";

function TyC({ show, handleClose, type }) {


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="perfil-usuario"
    >
      <Modal.Header closeButton>
        <AreaTitle addClass="p-0" title={"Términos y condiciones"} />
      </Modal.Header>

      <Modal.Body>
        <b style={{ fontSize: "0.8rem" }}>
          Términos y Condiciones Generales del uso del Sitio https://portalsalud.larioja.gob.ar
        </b>
        <p style={{ fontSize: "0.8rem" }}>

          A)  Términos y Condiciones generales y su aceptación

          Los Términos y Condiciones Generales (en adelante las "Condiciones Generales") son aplicables a la utilización de los servicios y contenidos suministrados por el Sitio del portal del paciente de la provincia de la Rioja (en adelante "PPLA") pone a disposición para ciudadanos previamente dados de alta por PPLA.

          El correcto acceso y funcionamiento del SITIO se encuentra condicionado al estricto cumplimiento de los avisos, reglamentos de uso e instrucciones presentes o las que en el futuro pudieren determinarse, efectuando PPLA expresa reserva del derecho de variar estas condiciones.

          Por consiguiente, el usuario y/o beneficiario acepta expresamente el derecho de PPLA de variar, restringir y/o suprimir, discrecionalmente y sin previo aviso, el servicio brindado por el SITIO, en forma total o parcial, de forma permanente o temporal. Siendo exclusiva responsabilidad del usuario y/o beneficiario la consulta periódica sobre el mantenimiento o variación de los términos y condiciones detallados en el presente.

          El servicio podrá verse interrumpido por breves períodos por razones de mantenimiento o de índole técnica, sin que ello habilite la posibilidad de reclamo por las molestias o daños que ello pudiera causarle al usuario y/o beneficiario.

          Asimismo, el usuario y/o beneficiario del servicio declara conocer que PPLA no se encuentra obligada a la prestación del mismo y que los trámites autorizados a efectuarse desde el SITIO, se pueden realizar en forma personal ante PPLA y/o cualquiera de los Centros de Atención que PPLA dispone para sus beneficiarios, dentro del horario de atención dispuesto.

          Es de exclusiva responsabilidad del Usuario y/o beneficiario la veracidad, precisión y actualización de la información personal, que se le pudiere solicitar.

          Obligaciones del Usuario

          Hacer uso correcto del SITIO

          Son obligaciones del Usuario y/o Beneficiario:

          utilizar el SITIO de conformidad con la ley, estas Condiciones Generales, la moral y buenas costumbres.
          utilizar el SITIO con fines lícitos y acorde a lo establecido en estas Condiciones Generales.


          B.2.1.1 Conducta general. Queda prohibido:

          utilizar el Sitio de modo que pudiere lesionar derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el normal funcionamiento o impedir la normal utilización del mismo por parte de los Usuarios.

          utilizar el SITIO, directa o indirectamente, para llevar adelante acciones violatorias de leyes y/o reglamentaciones;

          transmitir material moralmente inadecuado o violatorio de los derechos y garantías de terceros;

          transmitir, distribuir o almacenar cualquier tipo de información, datos o materiales que violen leyes o regulaciones internacionales, nacionales o provinciales;

          enviar o transmitir información cuyo contenido sea, directa o indirectamente, y sin que lo siguiente se considere una limitación, trasgresor, profano, abusivo, difamatorio y/o fraudulento, o que revele asuntos privados o personales que afecten a persona alguna, o que de alguna manera violen derechos de terceros;

          utilizar los servicios utilizando un nombre falso, erróneo o inexistente, ya sea como persona física o jurídica;

          Límite de Responsabilidad y Exclusión de Garantías

          General.

          La aceptación de los presentes términos y condiciones implica que el usuario y/o beneficiario excluye a PPLA de cualquier responsabilidad por los eventuales daños y perjuicios de toda índole y/o naturaleza.

          De manera no taxativa se podría enumerar que PPLA no garantiza, ni se responsabiliza por:

          La disponibilidad y continuidad del funcionamiento del SITIO.
          La utilidad del SITIO para la realización de ninguna actividad en particular.
          La infalibilidad del SITIO.
          La interrupción, suspensión,  finalización,  falta  de  disponibilidad  o  de  continuidad  del funcionamiento del SITIO.
          La eventual defraudación de la utilidad que los Usuarios hubieren podido atribuir al SITIO.
          Los eventuales fallos en el acceso a las distintas páginas web del SITIO.

          D.1 Particular

          D.1.2 PPLA NO SERA RESPONSABLE:

          POR LA INTERPRETACIÓN Y/O POR LA INCORRECTA INTERPRETACIÓN DE LO EXPRESADO EN EL SITIO EN FORMA EXPLÍCITA O IMPLÍCITA.

          POR EL USO INDEBIDO DEL SITIO.

          POR LOS EVENTUALES PERJUICIOS DIRECTOS O INDIRECTOS CAUSADOS POR QUIENES TOMEN LIBREMENTE U OMITAN TOMAR DECISIONES O MEDIDAS, AL CONSULTAR EL SITIO.

          POR CUALQUIER EVENTUAL TRANFERENCIA DE DATOS.

          POR LA EVENTUA AFECTACION AL EQUIPO O SISTEMA INFORMATICO DEL USUARIO Y/O BENEFICIARIO A CAUSA DE  ALGUN VIRUS O CUALQUIER OTRO CONTENIDO QUE PUDIERE AFECTAR O
          PRODUCIR ALTERACION ALGUNA. Es exclusiva responsabilidad del Usuario y/o beneficiario contar con los sistemas antivirus, sistemas de seguridad y prevención informáticos adecuados, así como cualquier otra posible herramienta, apta para proteger su ordenador.

          e) POR LOS DAÑOS Y PERJUICIOS DE CUALQUIER INDOLE O NATURALEZA QUE PUDIEREN DEBERSE, ENTRE OTROS, A LA OBTENCION, TRANSMISION, DIFUSION, RECEPCION, ALMACENAMIENTO, PUESTA A DISPOSICION, OBTENCION Y ACCESO A LOS CONTENIDOS.

          E. Derecho de Revocación

          PPLA se reserva el derecho, en cualquier momento y sin necesidad de darle notificación previa, de denegar, retirar el acceso al SITIO, o dar por concluido su calidad de Usuario del SITIO por violación de las condiciones generales aquí descriptas.

          General

          El presente acuerdo no podrá ser interpretado como un contrato de sociedad, mandato, agencia, o cualquier otra figura jurídica que genere ningún tipo de relación, entre PPLA y el Usuario.

          Si alguna de las condiciones que anteceden fuera declarada nula, dicha nulidad no afectará en modo alguno la validez o exigibilidad del resto de las condiciones previstas en este acuerdo.

          El presente no podrá ser enmendado o modificado unilateralmente por el Usuario.

          La falta de acción con respecto a una violación del convenio por parte del Usuario u otros no afecta nuestro derecho actuar frente a una violación posterior o similar.

          Los postulados del presente acuerdo, referentes a la responsabilidad de PPLA, no podrán interpretarse en forma taxativa. Están puestos únicamente como referencia, y de ninguna manera definen, limitan, delimitan o describen el ámbito o la extensión de dicha sección.

          Jurisdicción y Derecho Aplicable

          Las  presentes  Condiciones  Generales  se   regirán   por   las   leyes   de   la   República   Argentina. Las partes acuerdan que cualquier disputa o reclamo que surja de o con relación a, o en conexión con la ejecución o cumplimiento de este Acuerdo, incluyendo sin limitación, cualquier disputa sobre la validez, interpretación, exigibilidad o incumplimiento de este Acuerdo, será exclusiva y finalmente resueltas por la Justicia Nacional Ordinaria en lo Comercial de la Capital Federal de la República Argentina.

          Propiedad Intelectual

          Todos los contenidos de este sitio, incluyendo, de forma no taxativa, los textos, gráficos, logos, iconos, imágenes, archivos de audio y video, software y todas y cada una de las características que se encuentran en el SITIO son propiedad exclusiva de PPLA y los mismos están protegidos por las leyes internacionales de propiedad intelectual.

          El software y la totalidad de los Contenidos del Sitio son propiedad exclusiva de PPLA, así como sus mejoras y/o modificaciones. El uso indebido así como su reproducción serán objeto de las acciones judiciales que correspondan. La utilización de los servicios brindados por PPLA no podrá, en ningún supuesto, ser interpretada por el usuario y/o beneficiario como una autorización y/o concesión de licencia de los derechos intelectuales de PPLA y/o de un tercero.

          Queda terminantemente prohibido el uso contrario a las disposiciones establecidas, incluyendo la reproducción, modificación, distribución y/o transmisión, ya sea total o parcial, de los Contenidos del SITIO.

          Los gráficos, logos y nombres que identifican los planes, contenidos y/o servicios son marcas registradas de PPLA. Estas marcas registradas no pueden ser usadas.

          Política de privacidad

          Los datos aportados son idénticos y/o complementarios a los ya informados por el beneficiario a PPLA, y serán tratados para brindar servicios a los ciudadanos en las condiciones de privacidad establecidas por la normativa vigente.

          Sin perjuicio de que el titular de los datos personales no se encuentra obligado a proporcionar datos sensibles, se responsabiliza por la exactitud de los datos proporcionados.

          El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en los términos establecidos por Ley N° 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, órgano de control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.


          CONFORMIDAD

          La utilización y registración de datos en el SITIO implica la aceptación expresa, lisa, llana y sin reservas por parte del Usuario y/o Beneficiario de todos y cada uno de los Términos y Condiciones Generales ut supra detallados, desde el momento en que el Usuario acceda al mismo.

          El Usuario acepta y entiende que el uso y o interpretación de la información brindada en este sitio y las decisiones que se tomen en razón de las mismas, son realizadas enteramente bajo su propio riesgo.

          Queda expresamente aclarado que las decisiones a que el Usuario arribe son producto del ejercicio de sus facultades discrecionales.

          Es condición previa para acceder a los beneficios de la utilización del sitio, aceptar los términos y condiciones expuestos, de lo contrario no podrá acceder al mismo.
        </p>

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="me-2"
          onClick={() => handleClose()}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TyC;
