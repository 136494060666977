import { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as MdIcon from "react-icons/md";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import "../../styles/Transitions.scss";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";

function LoginPerson() {
  const [loading, setLoading] = useState(false);
  const [saveData, setSaveData] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const previousObjetURL = location.state?.from;
  const [number, setNumber] = useState(2);

  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("loginDataEmail")) || ""
  );

  const [password, setPassword] = useState(
    JSON.parse(localStorage.getItem("loginDataPassword")) || ""
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (auth.isLogged()) history.push(previousObjetURL || "/usuario");
  }, [auth, history, previousObjetURL]);

  const onSubmit = () => {
    auth.loginPerson(email, password);
    if (saveData) {
      localStorage.setItem("loginDataEmail", JSON.stringify(email));
      localStorage.setItem("loginDataPassword", JSON.stringify(password));
    }
  };

  useEffect(() => {
    setLoading(auth.loading);
  }, [auth]);

  const handlePassword = (value) => {
    setPassword(value);
    setValue("password", value);
  };

  useEffect(() => {
    setNumber(Math.floor(Math.random() * (5 - 0)) + 1);
  }, []);

  return (
    <>
      {loading ? (
        <Loader isActive={loading} />
      ) : (
        <div className={`bg-container bg${number} `}>
          <Form className="form-group in" onSubmit={handleSubmit(onSubmit)}>
            <BasicCard
              addClass="z-index-1 cont"
              removeDivider={true}
              header={<AreaTitle title="Iniciar sesión" addClass="p-3" />}
              footer={
                <div className="d-flex flex-row align-items-center justify-content-end p-3 gap-3">
                  <Link to="/register">
                    <button className="btn btn-outline-secondary">
                      Crear cuenta
                    </button>
                  </Link>

                  <Button variant="danger" type="submit">
                    Iniciar sesión
                  </Button>
                </div>
              }
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  value={email}
                  className="form-control"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "El campo es requerido.",
                    },
                    pattern: {
                      value: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                      message: "El formato ingresado no es válido",
                    },
                  })}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {errors.email && (
                  <ErrorMessage>
                    <p>{errors.email.message}</p>
                  </ErrorMessage>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Form.Control
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    className="form-control"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "El campo es requerido.",
                      },
                      minLength: {
                        value: 3,
                        message:
                          "La contraseña debe tener al menos 3 caracteres",
                      },
                    })}
                    onChange={(e) => {
                      handlePassword(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <MdIcon.MdVisibilityOff className="text-secondary" />
                    ) : (
                      <MdIcon.MdVisibility className="text-secondary" />
                    )}
                  </button>
                </div>
                {errors.password && (
                  <ErrorMessage>
                    <p>{errors.password.message}</p>
                  </ErrorMessage>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setSaveData(e.target.value);
                    }}
                  />{" "}
                  Recordar usuario y contraseña
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <Link to="/recuperar-clave" className="text-danger">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Form.Label>
              </Form.Group>
            </BasicCard>
          </Form>
        </div>
      )}
    </>
  );
}

export default LoginPerson;
