import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SidebarData } from "../../components/Sidebar/SidebarData";
import InstitucionesRouter from "./InstitucionesRouter";
import * as FaIcon from "react-icons/fa";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { BasicCard } from "../../components/BasicCard/BasicCard";

export default function Instituciones() {
  const routes = SidebarData.instituciones;

  return (
    <BasicCard
      header={
        <AreaTitle
          addClass="p-3"
          title="Establecimientos"
          icon={<FaIcon.FaHospital />}
        />
      }
    >
      <Row>
        <Col className="switch-container p-3">
          {routes.map((route) => {
            return (
              <NavLink
                key={route.path}
                className="me-2"
                activeClassName="active-switch"
                to={route.path}
              >
                {route.title}
              </NavLink>
            );
          })}
        </Col>
      </Row>
      <InstitucionesRouter></InstitucionesRouter>
    </BasicCard>
  );
}
