import React from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export const Truncate = ({
  text,
  maxLines,
  maxWords,
  addClass,
  showTooltip,
  fontSize,
}) => {
  const truncateText = () => {
    if (maxWords) {
      const words = text.split(" ");
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(" ") + "...";
      }
    }
    return text;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <>
      {showTooltip ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span
            className={`truncate ${addClass}`}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: maxLines || 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: fontSize,
            }}
          >
            {truncateText()}
          </span>
        </OverlayTrigger>
      ) : (
        <span
          className={`truncate ${addClass}`}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: maxLines || 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: fontSize,
          }}
        >
          {truncateText()}
        </span>
      )}
    </>
  );
};

Truncate.propTypes = {
  text: PropTypes.string.isRequired,
  maxLines: PropTypes.number,
  maxWords: PropTypes.number,
  addClass: PropTypes.string,
  showTooltip: PropTypes.bool,
  fontSize: PropTypes.string,
};

Truncate.defaultProps = {
  maxLines: 2,
  maxWords: null,
  addClass: "",
  showTooltip: true,
  fontSize: "14px",
};
