import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import usePatient from "../../hooks/usePatient";
import * as MdIcon from "react-icons/md";
import { getInstitutionsHSI } from "../../services/applicactionService";
import { useForm } from "react-hook-form";
import AutocompleteComponent from "../../components/AutocompleteComponent";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import { error } from "../../components/SwalAlertData";
import FormGroup from "../../components/RegisterForm/Forms/FormGroup";
import { getInstitutionPatientActivities } from "../../services/healthCertificate-hsi";
import MedicalCertificatePDF from "./components/MedicalCertificatePDF";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import * as PiIcon from "react-icons/pi";
import { DataNotFound } from "../../components/DataNotFound/DataNotFound";

const mokeRequest = {
  institution: "10460422155034",
  patient_dni: "17544017",
  dateFrom: null,
  dateTo: null,
};

const HealthCertificate = () => {
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const p = usePatient();
  const [institutions, setInstitutions] = useState([]);
  const [selectedInst, setSelectedInst] = useState(null);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const [data, setData] = useState([]);

  const [valuesForm, setValuesForm] = useState({
    institution: "",
    patient_dni: p.patient.identification_number,
    dateFrom: null,
    dateTo: null,
  });

  const getInstitutions = useCallback(() => {
    getInstitutionsHSI()
      .then((res) => {
        if (Array.isArray(res)) {
          setInstitutions(res);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire(error("Error al cargar centros médicos"));
      })
      .finally(() => setLoading(false));
  }, [institutions]);

  useEffect(() => {
    getInstitutions();
  }, []);

  const handleChange = (date, key) => {
    if (date) {
      setValuesForm({
        ...valuesForm,
        [key]: date,
      });
      setValue(key, date);
    }
  };

  const handleSearchInstitution = (value) => {
    if (typeof value !== "string" && value.id) {
      let selected = institutions.find((item) => {
        return (
          item.description.toLowerCase().trim() === value.name.toLowerCase()
        );
      }).sisaCode;
      setSelectedInst(value.name);
      setValuesForm({ ...valuesForm, institution: selected });
      setValue("institution", selected);
    }
  };

  const onSubmit = () => {
    setLoadingData(true);
    const inst = valuesForm.institution;
    const patient = valuesForm.patient_dni;
    const from = valuesForm.dateFrom.toISOString().split("T")[0];
    const to = valuesForm.dateTo.toISOString().split("T")[0];
    getHealthCertficate(inst, patient, from, to);
  };

  const getHealthCertficate = useCallback(
    (institution, patient_dni, dateFrom, dateTo) => {
      getInstitutionPatientActivities(
        institution,
        patient_dni,
        dateFrom,
        dateTo
      )
        .then((res) => {
          setLoadingData(false);
          setData(res);
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(error("Error al obtener información"));
        });
    },
    []
  );

  return (
    <BasicCard
      header={
        <AreaTitle
          title="Certificado de Salud"
          icon={<PiIcon.PiCertificateFill />}
          addClass="pt-3 px-3"
          subtitle="Completá el formulario para generar tu Certificado de Control de Salud."
        />
      }
    >
      <Row>
        {loading ? (
          <Loader isActive={loading} />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} lg={6}>
              <Form.Group className="mt-4">
                <Form.Label>Centro Médico</Form.Label>
                <AutocompleteComponent
                  variants={institutions}
                  handleChange={handleSearchInstitution}
                  className="position-sticky"
                  {...register("institution", {
                    required: {
                      value: true,
                      message: "El campo es requerido.",
                    },
                  })}
                />
                {errors.institution && (
                  <ErrorMessage>
                    <p>{errors.institution.message}</p>
                  </ErrorMessage>
                )}
              </Form.Group>
            </Col>
            <Col className="mt-4 mb-2" xs={12} lg={3}>
              <FormGroup
                inputType="datePicker"
                label="Desde (Seleccionar fecha)"
                name="dateFrom"
                selectValue={valuesForm.dateFrom}
                minDate={new Date("2000-01-01")}
                maxDate={new Date()}
                {...register("dateFrom", {
                  required: {
                    value: true,
                    message: "El campo es requerido.",
                  },
                })}
                handleChange={(date) => handleChange(date, "dateFrom")}
              />
              {errors.dateFrom && (
                <ErrorMessage>
                  <p>{errors.dateFrom.message}</p>
                </ErrorMessage>
              )}
            </Col>
            <Col className="mt-4 mb-2" xs={12} lg={3}>
              <FormGroup
                inputType="datePicker"
                label="Hasta (Seleccionar fecha)"
                name="dateTo"
                selectValue={valuesForm.dateTo}
                minDate={valuesForm.dateFrom}
                maxDate={new Date()}
                {...register("dateTo", {
                  required: {
                    value: true,
                    message: "El campo es requerido.",
                  },
                })}
                handleChange={(date) => handleChange(date, "dateTo")}
              />
              {errors.dateTo && (
                <ErrorMessage>
                  <p>{errors.dateTo.message}</p>
                </ErrorMessage>
              )}
            </Col>
            {!loadingData ? (
              <div>
                <Col xs={12} lg={6} className="mt-4 d-flex justify-content-end">
                  <Button variant="danger" className="me-2" type="submit">
                    <MdIcon.MdOutlineFactCheck className="me-2" />
                    Generar certificado
                  </Button>
                </Col>
                {data.length > 0 ? (
                  <MedicalCertificatePDF
                    appointments={data}
                    institution={selectedInst}
                    to={valuesForm.dateTo}
                    from={valuesForm.dateFrom}
                  />
                ) : 
                  <DataNotFound text='certificado de salud, con los datos ingresados'></DataNotFound>
                }
              </div>
            ) : (
              <Loader isActive={loadingData}></Loader>
            )}
          </Form>
        )}
      </Row>
    </BasicCard>
  );
};

export default HealthCertificate;
