import { Link } from "react-router-dom";
import noDataImage from "../../assets/images/not_found.png";

export default function NotFound() {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "calc(100vh - 48px)", width: "calc(100% - 240px)", marginLeft: "240px" }}
      >
        <img className="imageSizeEmpty" src={noDataImage} alt="No se encontraron datos" />
        <p className="text-center text-secondary">Error 404... Página no encontrada.</p>
        <Link to="/">Volver al inicio</Link>
      </div>
    </>
  );
}
