import { Col, Row } from "react-bootstrap";
import * as MdIcon from 'react-icons/md'
import Swal from "sweetalert2";
import { confirm, error } from "../../../components/SwalAlertData";
import { useCallback } from "react";
import { cancelAppointment } from "../../../services/applicactionService";

function AppointmentCard({ appointmentData, status, reset }) {
console.log(appointmentData)
    const doctor = appointmentData.doctor.lastName + ', ' + appointmentData.doctor.name
    const specialty = appointmentData.doctor.specialtyList[0].description
    const isOnline = appointmentData.isOnline
    const institution = appointmentData.institution ? appointmentData.institution.name : ''
    const institutionId = appointmentData.institution ? appointmentData.institution.id : ''
    const address = appointmentData.institution ? (appointmentData.institution.address + ', ' + appointmentData.institution.city) : ''
    const date = new Date(appointmentData.day+'T00:00:00').toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    const appointmentStatus = appointmentData.appointmentStatus.description;

    const handleCancel = () => {
        Swal.fire(confirm('¿Confirmás la cancelación del turno?')).then((res) => {
            if (res.isConfirmed) {
                appointmentCancel()
            }
        })
    }

    const appointmentCancel = useCallback(() => {
        cancelAppointment(institutionId, appointmentData.id)
            .then((res) => {
                if (res) {
                    reset();
                }
            })
            .catch((err) => {
                console.error(err);
                Swal.fire(error('Ocurrió un error al intentar cancelar el turno.'));
            });
    }, [])

    return (
        <Row className={`appointment-card in ${status !== "3" ? 'bg-third' : 'bg-secondary-light'}`}>
            <Col xs={12}>
                <h5 className="mb-0">{doctor}</h5>
                <p>{specialty}</p>
                {isOnline && <span className="d-block text-secondary"><MdIcon.MdOutlineVideoCall /> Teleasistencia</span>}
                {!isOnline && <span className="d-block text-secondary"><MdIcon.MdPersonOutline /> Presencial</span>}
                <span>{institution}</span>
                <span>{address}</span>
                <hr />
            </Col>
            <Col xs={12}>
                <span>{date} - {appointmentData.hour}</span>
            </Col>
            <Col xs={12} className="d-flex justify-content-end mt-2">
                {status !== "3" ? <div className="my-tooltip" >
                    <button className='btn text-danger ms-0' onClick={() => { handleCancel() }}>
                        <MdIcon.MdOutlineEventBusy style={{ fontSize: '1.5rem' }} /> Cancelar turno
                    </button>
                    {/* <span className="tiptext">
                        Cancelar turno
                    </span> */}
                </div> :
                    <span>
                        {appointmentStatus === 'Atendido' ?
                            <MdIcon.MdCheckCircleOutline className="text-success me-2" style={{ fontSize: '1.5rem' }} />
                            : <MdIcon.MdOutlineCancel className="text-danger me-2" style={{ fontSize: '1.5rem' }} />
                        }
                        Turno {appointmentStatus}
                    </span>
                }
            </Col>
        </Row>
    )
}

export default AppointmentCard;
