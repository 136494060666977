import React from "react";
import "./AreaTitle.scss";

import PropTypes from "prop-types";

/**
 * Componente AreaTitle
 * 
 * Este componente se utiliza para mostrar un título de sección con un icono opcional,
 * subtítulo y componentes adicionales a la izquierda y derecha.
 * 
 * Props:
 * - title (string): El título de la sección.
 * - icon (node): Un icono opcional que se muestra junto al título.
 * - rightComponents (arrayOf(node)): Componentes adicionales que se muestran a la derecha.
 * - leftComponents (arrayOf(node)): Componentes adicionales que se muestran a la izquierda.
 * - addClass (string): Clases CSS adicionales para el contenedor principal.
 * - addClassTitle (string): Clases CSS adicionales para el título.
 * - subtitle (string): Un subtítulo opcional que se muestra debajo del título.
 * 
 * Default Props:
 * - addClass: "pb-4"
 * - addClassTitle: ""
 */

export const AreaTitle = ({
  title,
  icon,
  rightComponents,
  leftComponents,
  addClass,
  addClassTitle,
  subtitle,
}) => {
  return (
    <>
      <div
        className={`d-flex flex-row justify-content-between w-100 ${addClass}`}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          {title && (
            <>
              {icon && (
                <div className={`menu-icon text-danger titleIcon ${subtitle ? "iconModification" : ""}`}>{icon}</div>
              )}
              <div className="d-flex flex-column">
                <h5 className={`section-title mb-0 ${addClassTitle}`}>{title}</h5>
                {subtitle && (
                  <p className="m-0 mt-1 mb-2 small">{subtitle}</p>
                )}
              </div>
            </>
          )}
          {leftComponents &&
            leftComponents.map((component, i) => {
              return component;
            })
          }
        </div>
        <div className="d-flex flex-row align-items-center gap-2">
          {rightComponents &&
            rightComponents.map((component, i) => {
              return component;
            })
          }
        </div>
      </div>
    </>
  );
};

AreaTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  rightComponents: PropTypes.arrayOf(PropTypes.node),
  leftComponents: PropTypes.arrayOf(PropTypes.node),
  addClass: PropTypes.string,
  addClassTitle: PropTypes.string,
  subtitle: PropTypes.string,
};

AreaTitle.defaultProps = {
  addClass: "pb-4",
  addClassTitle: "",
};
