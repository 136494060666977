import React, { useMemo } from "react";
import "./SingleViewer.scss";

import PropTypes from "prop-types";

export const SingleViewer = ({ items, title, labelAddClass, valueAddClass, verticalSeparation, horizontalSeparation }) => {
  const itemsToShow = useMemo(
    () => items.filter((item) => !item.isHidden),
    [items]
  );

  return (
    <>
      <div className="w-100">
        {title && (
          <>
            <h6 className="color-primary text-dark m-0 mt-3 fw-bold">{title}</h6>
            <hr></hr>
          </>
        )}
        <div className="d-flex flex-column w-100">
          {itemsToShow.map((item, index) => (
            <div
              key={`item--${index}`}
              className={`w-100 ${verticalSeparation}`}
              style={{
                display: "grid",
                gridTemplateColumns: `${horizontalSeparation} 1fr`,
              }}
            >
              <div
                className="d-flex flex-row gap-2"
              >
                {item.labelSufix}
                <h6 className={`text-secondary m-0 ${labelAddClass}`}>{item.label}</h6>
                {item.labelPrefix}
              </div>
              {!item.custom ? (
                <div
                  className="d-flex flex-row gap-2"
                >
                  {item.valueSufix}
                  <h6 className={`m-0 ${valueAddClass}`}>{item.value}</h6>
                  {item.valuePrefix}
                </div>
              ) : (
                item.custom
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

SingleViewer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  labelAddClass: PropTypes.string,
  valueAddClass: PropTypes.string,
  verticalSeparation: PropTypes.string,
  horizontalSeparation: PropTypes.string
};

SingleViewer.defaultProps = {
  items: [],
  labelAddClass: "",
  valueAddClass: "",
  verticalSeparation: "mb-3",
  horizontalSeparation: "12em"
};
