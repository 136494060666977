import { environment } from "../environments/environments.demo";

const baseUrlHSI = environment.hsi.apiUrlcv
const baseUrlPPLR = environment.baseURL

export const HSI_HEADER = () => {
    return { 
        "Content-type": "application/json",
        "Authorization": environment.hsi.apiKey 
    }
};

export const HSI_HEADER_BEARER = () => {
    return { 
        "authorization": environment.hsi.apiKey 
    }
};

export const HSI_HEADER_API_KEY = () => {
    return { 
        "Content-type": "application/json",
        "api-key": environment.hsi.apiKey 
    }
};


export const HSI_GET_INSTITUTIONS = `${baseUrlPPLR}/appointment/booking/institutionExtended`;
export const HSI_GET_SPECIALTY = `${baseUrlPPLR}/appointment/booking/specialties`;
export const HSI_GET_MEDICAL_COVERAGE = `${baseUrlPPLR}/appointment/booking/medicalCoverages`;

export const HSI_GET_AVAILABLE_APPOINTMENTS = (institution_id, specialty_id, medical_coverage_id) => {
    return `${baseUrlPPLR}/institution/${institution_id}/appointment/booking/specialty/${specialty_id}/practice/1/medicalCoverages/83/availability`;
};

export const HSI_POST_APPOINTMENT = (institution_id, person_id) => {
    return `${baseUrlPPLR}/institution/${institution_id}/appointment/booking?person_id=${person_id}`;
};

export const SEND_APPOINTMENT_MAIL = `${baseUrlPPLR}/send-appointment-mail-hsi`;

export const HSI_CANCEL_APPOINTMENT = (institution_id, appointment_id) => {
    return `${baseUrlPPLR}/institution/${institution_id}/appointment/${appointment_id}/cancel`;
};

export const HSI_GET_USER_APPOINTMENTS = (userData) => {
    return `${baseUrlHSI}/patient/${userData.dni}/appointments?identificationTypeId=${userData.identificationTypeId}&genderId=${userData.genderId}&birthDate=${userData.birthDate}`;
};

export const HSI_GET_INSTITUTION_PATIENT_ACTIVITIES = (institution_id, patient_dni, dateFrom, dateTo) => {    
    return `${baseUrlPPLR}/institution/refset/${institution_id}/patient/${patient_dni}/activities?from_date=${dateFrom}&to_date=${dateTo}&reprocessing=false`;
};

export const HSI_GET_PRESCRIPTIONS = (patient_dni) => {
    return `${baseUrlPPLR}/patient/${patient_dni}/prescriptions`;
};
