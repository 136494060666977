import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SidebarData } from "../../components/Sidebar/SidebarData";
import AdminPanelRouter from "./AdminPanelRouter";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { MdAdminPanelSettings } from "react-icons/md";

export default function AdminPanel() {
  const data = SidebarData.superadmin.find((d) => d.id === 16);
  const routes = data.options;

  return (
    <BasicCard
      header={
        <AreaTitle
          title="Panel de administradores"
          addClass="p-3"
          icon={<MdAdminPanelSettings />}
        />
      }
    >
      <Row>
        <Col className="switch-container p-3">
          {routes.map((route) => {
            return (
              <NavLink
                key={route.path}
                className="me-2"
                activeClassName="active-switch"
                to={route.path}
              >
                {route.title}
              </NavLink>
            );
          })}
        </Col>
      </Row>
      <AdminPanelRouter></AdminPanelRouter>
    </BasicCard>
  );
}
