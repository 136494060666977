import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import usePatient from "../../hooks/usePatient";
import { MdFamilyRestroom } from "react-icons/md";
import { Paciente } from "./Paciente/Paciente";
import Loader from "../../components/Loader";
import { BasicCard } from "../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";

function GrupoFamiliar() {
  const [loading, setLoading] = useState(true);
  const p = usePatient();
  const [dniPatient, setDniPatient] = useState(p.patient.identification_number);
  const history = useHistory();

  const handlePatient = (idn) => {
    setDniPatient(idn);
  };

  const verHistoriaClinica = (idn) => {
    setDniPatient(idn);
    setTimeout(() => {
      history.push("/usuario/historia-clinica/alergias");
    }, 300);
  };

  useEffect(() => {
    p.getPatient(dniPatient);
    setLoading(false);
  }, [dniPatient]);

  return (
    <>
      <BasicCard
        header={
          <AreaTitle
            title="Grupo familiar"
            addClass="p-3"
            icon={<MdFamilyRestroom />}
            rightComponents={[
              <Link to="/usuario/agregar-paciente">
                <button className="btn btn-danger">
                  Agregar
                </button>
              </Link>,
            ]}
          />
        }
      >
        {loading ? (
          <Loader isActive={loading} />
        ) : (
          <>
            {p.allPatients.map((patient, i) => {
              return (
                <>
                  {patient.id_admin_status === 2 && (
                    <>
                      <Paciente
                        key={patient.identification_number + i}
                        patientIdn={patient.identification_number}
                        patientNombre={patient.name}
                        patientApellido={patient.surname}
                        verHistoriaClinica={verHistoriaClinica}
                        handlePatient={handlePatient}
                      />
                    </>
                  )}
                </>
              );
            })}
          </>
        )}
      </BasicCard>
    </>
  );
}

export default GrupoFamiliar;
