import * as MdIcon from "react-icons/md";
import * as FaIcon from "react-icons/fa";
import * as PiIcon from "react-icons/pi";

export const SidebarData = {
  inicio: [
    {
      id: 1,
      title: "Inicio",
      pathUser: "/usuario",
      pathAdmin: "/admin",
      icon: <MdIcon.MdHome className="menu-icon"/>,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon"/>,
      options: false,
    },
  ],
  perfilDelPaciente: [
    {
      id: 2,
      title: "Perfil",
      path: "/usuario/perfil-paciente",
      icon: <MdIcon.MdPerson className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 3,
      title: "Notificaciones",
      path: "/usuario/notificaciones",
      icon: <MdIcon.MdNotifications  className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 4,
      title: "Turnos",
      path: false,
      icon: <MdIcon.MdAccessTimeFilled className="menu-icon" />,
      acordeon: true,
      a: false,
      cName: "sidebar-text acordeon",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Mis turnos",
          path: "/usuario/turnos/mis-turnos",
        },
        {
          title: "Solicitar turnos",
          path: "/usuario/turnos/solicitar-turnos"
        },
        {
          title: "Solicitar por correo",
          path: "/usuario/turnos/solicitar-turnos-email"
        }
      ]
    },
    {
      id: 5,
      title: "Estudios",
      path: "/usuario/estudios",
      icon: <MdIcon.MdTextSnippet className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text acordeon",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 6,
      title: "Historia clínica",
      path: false,
      icon: <MdIcon.MdFolderShared className="menu-icon" />,
      acordeon: true,
      a: false,
      cName: "sidebar-text acordeon",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Alergias",
          path: "/usuario/historia-clinica/alergias",
        },
        // {
        //   title: "Antecedentes personales",
        //   path: "/usuario/historia-clinica/antecedentes-personales",
        // },
        // {
        //   title: "Antecedentes familiares",
        //   path: "/usuario/historia-clinica/antecedentes-familiares",
        // },
        {
          title: "Datos antropométricos",
          path: "/usuario/historia-clinica/datos-antropometricos",
        },
        {
          title: "Datos crónicos",
          path: "/usuario/historia-clinica/datos-cronicos",
        },
        // {
        //   title: "Hospitalización",
        //   path: "/usuario/historia-clinica/hospitalizacion",
        // },
        {
          title: "Inmunización",
          path: "/usuario/historia-clinica/inmunizacion",
        },
        {
          title: "Medicación",
          path: "/usuario/historia-clinica/medicacion",
        },
        {
          title: "Problemas activos",
          path: "/usuario/historia-clinica/problemas-activos",
        },
        {
          title: "Problemas resueltos",
          path: "/usuario/historia-clinica/problemas-resueltos",
        },
        {
          title: "Registros dentales",
          path: "/usuario/historia-clinica/registros-dentales",
        },
        {
          title: "Signos vitales",
          path: "/usuario/historia-clinica/signos-vitales",
        },
      ],
    },
    {
      id: 18,
      title: "Certificado de salud",
      path: "/usuario/certificado-de-salud",
      icon: <PiIcon.PiCertificateFill  className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text acordeon",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 19,
      title: "Medicación",
      path: "/usuario/medicacion",
      icon: <MdIcon.MdMedication className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text acordeon",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 7,
      title: "Vacunación",
      path: "/usuario/vacunacion",
      icon: <MdIcon.MdVaccines  className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    {
      id: 8,
      title: "Programa Sumar",
      path: "/usuario/programa-sumar",
      icon: <MdIcon.MdAddCircle className="menu-icon" />,
      acordeon: true,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Historia clínica",
          path: "/usuario/programa-sumar/hc",
        },
        {
          title: "Cobertura  Efectiva Básica",
          path: "/usuario/programa-sumar/ceb",
        },
      ],
    },
  ],
  grupoFamiliar: [
    {
      id: 9,
      title: "Grupo familiar",
      path: "/usuario/grupo-familiar",
      icon: <MdIcon.MdFamilyRestroom className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    // {
    //   id: 10,
    //   title: "Cambiar Paciente",
    //   path: false,
    //   icon: <MdIcon.MdOutlineChangeCircle className="menu-icon" />,
    //   acordeon: true,
    //   a: false,
    //   cName: "sidebar-text",
    //   aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
    //   options: [ ],
    // },
  ],
  instituciones: [
    {
      id: 11,
      title: "Establecimientos",
      path: "/usuario/instituciones/centros-medicos",
      icon: <FaIcon.FaHospital className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
    // {
    //   id: 12,
    //   title: "Guardias",
    //   path: "/usuario/instituciones/guardias",
    //   icon: <MdIcon.MdEmergency className="menu-icon" />,
    //   acordeon: false,
    //   a: false,
    //   cName: "sidebar-text",
    //   aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
    //   options: false,
    // },
    {
      id: 13,
      title: "Datos institucionales",
      path: "/usuario/instituciones/datos-institucionales",
      icon: <MdIcon.MdAccountBalance className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: false,
    },
  ],
  admin: [
    {
      id: 14,
      title: "Alta de pacientes",
      path: "/admin/alta-de-pacientes/pacientes-pendientes",
      icon: <MdIcon.MdPersonSearch className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Pacientes pendientes",
          path: "/admin/alta-de-pacientes/pacientes-pendientes",
        },
        {
          title: "Pacientes rechazados",
          path: "/admin/alta-de-pacientes/pacientes-rechazados",
        },
      ],
    },
    {
      id: 15,
      title: "Mensajería",
      path: "/admin/mensajeria/mensajes-enviados",
      icon: <MdIcon.MdMessage className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Mensajes Enviados",
          path: "/admin/mensajeria/mensajes-enviados",
        },
        {
          title: "Borradores",
          path: "/admin/mensajeria/borradores",
        },
      ],
    },
    {
      id: 17,
      title: "Establecimientos",
      path: "/admin/establecimientos",
      icon: <FaIcon.FaHospital className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [],
    },
  ],
  superadmin: [
    {
      id: 16,
      title: "Panel de administradores",
      path: "/admin/panel/listado",
      icon: <MdIcon.MdAdminPanelSettings className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [
        {
          title: "Listado",
          path: "/admin/panel/listado",
        },
      ],
    },
    {
      id: 17,
      title: "Indicadores",
      path: "/admin/indicadores",
      icon: <MdIcon.MdAreaChart className="menu-icon" />,
      acordeon: false,
      a: false,
      cName: "sidebar-text",
      aIcon: <MdIcon.MdKeyboardArrowDown className="menu-icon" />,
      options: [],
    },
  ],
};
