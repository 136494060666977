import React, { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  confirmAppointment,
  error,
  success,
  warning,
} from "../../../components/SwalAlertData";
import usePatient from "../../../hooks/usePatient";
import { DataNotFound } from "../../../components/DataNotFound/DataNotFound";
import { Accordion, Table } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { postAppointment, sendAppointmentHSIEmail } from "../../../services/applicactionService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AppointmentTable({
  dataTable,
  valuesForm,
  institutions,
  specialties,
  coverage,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [mql, setMql] = useState(window.matchMedia("(min-width: 992px)"));
  const handleWindowSize = () => {
    window.addEventListener("resize", (event) => {
      setMql(window.matchMedia("(min-width: 992px)"));
    });
  };

  useEffect(() => {
    handleWindowSize();
  }, [window]);
  // //patient
  const p = usePatient();
  // //form
  const myDivRef = useRef(null);
  const [selectedAppointment, setSelectedAppointment] = useState("");

  const [values, setValues] = useState({});

  useEffect(() => {
    const newValues = {
      appointmentDataEmail: valuesForm.email,
      bookingAppointmentDto: {
        coverageId: valuesForm.coverage || "83",
        day: "",
        diaryId: 0,
        phoneNumber: valuesForm.phoneNumber,
        phonePrefix: "",
        hour: "",
        snomedId: 0,
        openingHoursId: 0,
        specialtyId: valuesForm.specialty,
      },
      bookingPersonDto: {
        idNumber: p.patient.identification_number,
        birthDate: p.patient.birthdate.split("T")[0],
        genderId: p.patient.id_gender,
        firstName: p.patient.name,
        lastName: p.patient.surname,
        phoneNumber: valuesForm.phoneNumber,
        phonePrefix: "",
        email: valuesForm.email,
      },
    };
    setValues(newValues);
  }, [valuesForm]);

  useEffect(() => {
    if (myDivRef.current) {
      const topOffset = 200;
      const elementPosition =
        myDivRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - topOffset, behavior: "smooth" });
    }
  }, [dataTable]);

  const handleSelected = (appointment, hour) => {
    if (
      valuesForm.email &&
      valuesForm.phoneNumber &&
      valuesForm.institution &&
      valuesForm.specialty
    ) {
      setSelectedAppointment(appointment);
      handleRequest(appointment, hour);
    } else {
      Swal.fire(warning("Faltan datos en formulario"));
    }
  };

  const handleRequest = (appointment, hour) => {
    const confirmData = {
      nameAndSurname:
        values.bookingPersonDto.firstName +
        " " +
        values.bookingPersonDto.lastName,
      email: values.bookingPersonDto.email,
      phoneNumber: values.bookingAppointmentDto.phoneNumber,
      date: localeDateString(appointment.slots.date),
      hour: hour,
      professional: appointment.diary.doctorsOfficeDescription,
      institution: institutions.find((i) => i.id === valuesForm.institution)
        .description,
      specialty: specialties.find((s) => s.id === valuesForm.specialty)
        .description,
      // coverage: coverage.find((c) => c.id === valuesForm.coverage).description
    };
    Swal.fire(confirmAppointment(confirmData)).then((result) => {
      if (result.isConfirmed) {
        send(builRequestBody(appointment, hour), confirmData);
      }
    });
  };

  const localeDateString = (date) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const [y, m, d] = date.split("-");
    return new Date(Number(y), Number(m) - 1, Number(d)).toLocaleDateString(
      "es-ES",
      options
    );
  };

  const builRequestBody = (appointment, hour) => {
    const bookingAppointmentDto = {
      ...values.bookingAppointmentDto,
      diaryId: appointment.diary.id,
      day: appointment.slots.date,
      hour: hour,
      openingHoursId: appointment.diary.openingHoursId
    }
    const body = {
      ...values,
      bookingAppointmentDto: bookingAppointmentDto,
    };
    return body;
  };

  const send = useCallback((requestBody, emaildata) => {
    postAppointment(requestBody, valuesForm.institution, p.patient.id)
      .then((res) => {
        if (res.ok) {
          Swal.fire(success("La solicitud fue enviada con éxito"));
          sendMail(requestBody, emaildata)
          history.push("/usuario/turnos/mis-turnos");
        } else {
          throw new Error("Hubo un error al enviar la solicitud");
        }
      })
      .catch((err) => {
        console.error("error", err);
        Swal.fire(error("Hubo un error al enviar la solicitud"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sendMail = useCallback((appointment, data) => {
    const requestEmail = {
      person_id: p.patient.id,
      institution_name: data.institution,
      specialty_name: data.specialty,
      professional_name: data.professional,
      appointment_day: appointment.bookingAppointmentDto.day,
      appointment_hour: data.hour
    }

    sendAppointmentHSIEmail(requestEmail).then();
  }, []);

  const formatDateToDDMMYYYY = (dateString) => {
    const [y, m, d] = dateString.split("-");
    return d + "/" + m + "/" + y;
  };

  return (
    <>
      {loading ? (
        <Loader isActive={loading}></Loader>
      ) : (
        <div ref={myDivRef} style={{ minHeight: "75vh" }} className="in">
          {dataTable && dataTable.length > 0 ? (
            <div>
              <h5>Seleccionar turno</h5>

              {dataTable.map((item, i) => {
                return (
                  <Accordion key={i} className="border boder-secondary">
                    <Accordion.Header>
                      Dr./Dra. {item.professional.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      {item.availability?.map((appointment, index) => {
                        return (
                          <Accordion
                            key={"diary" + index}
                            className="border boder-secondary"
                          >
                            <Accordion.Header>
                              {localeDateString(
                                appointment.slots.date
                              ).toUpperCase()}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Table
                                striped
                                bordered
                                borderless
                                hover
                                className="text-center"
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    {mql.matches && <th>Fecha</th>}
                                    <th>Hora</th>
                                    {mql.matches && <th>Profesional</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {appointment?.slots.slots.map((hour) => {
                                    return (
                                      <tr
                                        key={hour + index}
                                        onClick={() => {
                                          handleSelected(appointment, hour);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <td>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="selectedAppointment"
                                            id="selectedAppointment1"
                                            onChange={() => {}}
                                            checked={
                                              appointment.diary.id ===
                                              selectedAppointment?.diary?.id
                                            }
                                          />
                                        </td>
                                        {mql.matches && (
                                          <td>
                                            {formatDateToDDMMYYYY(
                                              appointment.slots.date
                                            )}
                                          </td>
                                        )}
                                        <td>{hour}</td>
                                        {mql.matches && (
                                          <td>
                                            {
                                              appointment.diary
                                                .doctorsOfficeDescription
                                            }
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion>
                );
              })}
            </div>
          ) : (
            <DataNotFound text="turnos disponibles" />
          )}
        </div>
      )}
    </>
  );
}

export default AppointmentTable;
