import { AUTH_HEADER, INDICADOR_APPOINTMENT, INDICADOR_CANTIDAD_USUARIOS, INDICADOR_GRUPO_FAMILIAR, INDICADOR_HCE, INDICADOR_STUDIES, INDICADOR_SUMAR, INDICADOR_TURNOS, INDICADOR_USUARIOS_ACTIVOS, INDICADOR_USUARIOS_MASTER, INDICADOR_USUARIOS_PENDIENTES, INDICADOR_USUARIOS_RECHAZADOS, INDICADOR_USUARIOS_VALIDADOS, MONTHLY_INDICADOR_APPOINTMENT, MONTHLY_INDICADOR_HCE, MONTHLY_INDICADOR_STUDIES, MONTHLY_INDICADOR_SUMAR } from "../constants/api.constants";
import { get } from "./httpServices";

export async function getUsersIndicators() {
  try {
    const promise = await get((INDICADOR_USUARIOS_ACTIVOS()), AUTH_HEADER()) 
   return promise
  }
  catch (err) {
    console.log('Error al cargar datos: ', err);
  }
}

export async function getFamilyGroupIndicators() {
    try {
      const promise = await get((INDICADOR_GRUPO_FAMILIAR()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getValidatedUsersIndicators() {
    try {
      const promise = await get((INDICADOR_USUARIOS_VALIDADOS()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getRejectedUsersIndicators() {
    try {
      const promise = await get((INDICADOR_USUARIOS_RECHAZADOS()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getPendingUsersIndicators() {
    try {
      const promise = await get((INDICADOR_USUARIOS_PENDIENTES()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }
  
  export async function getQuantityUsersIndicators() {
    try {
      const promise = await get((INDICADOR_CANTIDAD_USUARIOS()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getMasterUsersIndicators() {
    try {
      const promise = await get((INDICADOR_USUARIOS_MASTER()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  
  export async function getTurnosIndicators() {
    try {
      const promise = await get((INDICADOR_TURNOS()), AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getTurnosHSIIndicatorsAll() {
    try {
      const promise = await get(INDICADOR_APPOINTMENT, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  
  export async function getTurnosHSIIndicatorsMonthly() {
    try {
      const promise = await get(MONTHLY_INDICADOR_APPOINTMENT, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }
  
  export async function getStudiesIndicators() {
    try {
      const promise = await get(INDICADOR_STUDIES, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getStudiesMonthlyIndicators() {
    try {
      const promise = await get(MONTHLY_INDICADOR_STUDIES, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getHCEIndicators() {
    try {
      const promise = await get(INDICADOR_HCE, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getHCEMonthlyIndicators() {
    try {
      const promise = await get(MONTHLY_INDICADOR_HCE, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getSumarIndicators() {
    try {
      const promise = await get(INDICADOR_SUMAR, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }

  export async function getSumarMonthlyIndicators() {
    try {
      const promise = await get(MONTHLY_INDICADOR_SUMAR, AUTH_HEADER()) 
     return promise
    }
    catch (err) {
      console.log('Error al cargar datos: ', err);
    }
  }


  