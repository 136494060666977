
import React from "react";
import Card from "react-bootstrap/Card";
import { Scroll } from "../Scroll/Scroll";
import "./BasicCard.scss";

import PropTypes from "prop-types";

/**
 * Componente BasicCard
 * 
 * Este componente representa una tarjeta básica que utiliza el componente Card de react-bootstrap.
 * Permite incluir un encabezado, un pie de página y contenido desplazable.
 * 
 * Props:
 * - children: Contenido que se mostrará dentro de la tarjeta.
 * - addClass: Clases adicionales para personalizar el estilo de la tarjeta.
 * - scrollProps: Propiedades para el componente Scroll, incluyendo la dirección del desplazamiento y clases adicionales.
 * - footer: Contenido que se mostrará en el pie de página de la tarjeta.
 * - header: Contenido que se mostrará en el encabezado de la tarjeta.
 * - removeDivider: Booleano que indica si se debe eliminar el divisor entre el encabezado/pie de página y el contenido.
 */
export const BasicCard = ({
  children,
  addClass,
  scrollProps,
  footer,
  header,
  removeDivider,
}) => {
  return (
    <Card className={`h-100 w-100 ${addClass} BasicShadow p-0`}>
      {header && (
        <>
          {header}
          {!removeDivider && <hr className="m-0" />}
        </>
      )}
      <Scroll
        scrollDirection={scrollProps.scrollDirection}
        addClass={`p-3 ${scrollProps.addClass}`}
        {...scrollProps}
      >
        {children}
      </Scroll>
      {footer && (
        <>
          {!removeDivider && <hr className="m-0" />}
          {footer}
        </>
      )}
    </Card>
  );
};

BasicCard.propTypes = {
  children: PropTypes.node.isRequired,
  addClass: PropTypes.string,
  scrollProps: PropTypes.shape({
    scrollDirection: PropTypes.oneOf(["vertical", "horizontal"]),
    addClass: PropTypes.string,
  }),
  footer: PropTypes.node,
  header: PropTypes.node,
  removeDivider: PropTypes.bool,
};

BasicCard.defaultProps = {
  scrollProps: {
    scrollDirection: "vertical",
  },
  removeDivider: false,
};
