import { Link } from "react-router-dom";
import RegisterForm from "../../../components/RegisterForm/RegisterForm";
import { BasicCard } from "../../../components/BasicCard/BasicCard";
import { AreaTitle } from "../../../components/AreaTitle/AreaTitle";
import { MdPersonAddAlt1 } from "react-icons/md";

export default function AgregarPaciente() {
  return (
    <BasicCard
      header={
        <AreaTitle
          title="Agregar paciente al grupo familiar"
          addClass="p-3"
          icon={<MdPersonAddAlt1 />}
          rightComponents={[
            <Link to="/usuario/grupo-familiar">
              <button className="btn btn-outline-secondary">Cancelar</button>
            </Link>,
          ]}
        />
      }
    >
      <RegisterForm formType={"patient"}></RegisterForm>
    </BasicCard>
  );
}
