import { useCallback, useEffect, useState } from "react";
import usePatient from "../../../../hooks/usePatient";
import { variantsGender } from "../../../../components/ComponentsData";
import { getInstitutionsAllWithNewData } from "../../../../services/institutionsServices";
import { SingleViewer } from "../../../../components/SingleViewer/SingleViewer";
import { Tag } from "../../../../components/Tag/Tag";

function DatosPaciente() {
  const p = usePatient();
  const birthdate = new Date(p.patient.birthdate).toLocaleDateString();

  function calculateAge(birthdate) {
    let today = new Date();
    let b = new Date(birthdate);
    let age = today.getFullYear() - b.getFullYear();
    let m = today.getMonth() - b.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < b.getDate())) {
      age--;
    }
    return age;
  }
  const age = calculateAge(p.patient.birthdate);
  const gender = variantsGender.find((v) => v.id === p.patient.id_gender).name;
  const [institution, setInstitution] = useState([]);
  const getInstitutions = useCallback(() => {
    getInstitutionsAllWithNewData()
      .then((res) => {
        return res;
      })
      .then((res) => {
        let find = res.find((i) => {
          return i.id === p.patient.id_usual_institution;
        });
        if (find) setInstitution(find.name);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [institution]);

  useEffect(() => {
    getInstitutions();
  }, []);

  return (
    <>
      <SingleViewer
        title="Datos personales"
        items={[
          {
            label: "Nombre y apellido:",
            value: `${p.patient.name || " - "} ${p.patient.surname || " - "}`,
          },
          {
            label: "D.N.I:",
            value: `${p.patient.identification_number || " - "}`,
          },
          {
            label: "Fecha de nacimiento:",
            value: `${birthdate || " - "}`,
          },
          {
            label: "Edad:",
            value: `${age || " - "}`,
          },
          {
            label: "Genero:",
            value: `${gender || " - "}`,
          },
        ]}
      />
      <SingleViewer
        title="Datos de contacto"
        items={[
          {
            label: "Email de usuario:",
            value: `${p.patient.email || " - "}`,
          },
          {
            label: "Teléfono de usuario:",
            value: `${p.patient.phone_number || " - "}`,
          },
          {
            label: "Domicilio de usuario:",
            value: `${p.patient.address_street || " - "} ${
              p.patient.address_number || " - "
            }, ${p.patient.locality || " - "}, ${
              p.patient.department || " - "
            }`,
          },
        ]}
      />
      <SingleViewer
        title="Datos de atención"
        items={[
          {
            label: "Centro de atención:",
            value: `${institution || " - "}`,
          },
          {
            label: "Afecciones crónicas:",
            custom: (
              <div className="d-flex flex-wrap gap-2">
                {p.patient.is_diabetic && <Tag title={"Diabetes"} />}
                {p.patient.is_hypertensive && <Tag title={"Hipertensión"} />}
                {p.patient.is_chronic_kidney_disease && (
                  <Tag title={"Enfermedad renal"} />
                )}
                {p.patient.is_chronic_respiratory_disease && (
                  <Tag title={"Enfermedad respiratoria"} />
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default DatosPaciente;
