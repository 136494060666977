import React, { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import usePatient from "../../hooks/usePatient";
import * as MdIcon from "react-icons/md";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import { error } from "../../components/SwalAlertData";
import { DataNotFound } from "../../components/DataNotFound/DataNotFound";
import { getPrescriptions } from "../../services/healthCertificate-hsi";
import PrescriptionCard from "./components/PrescriptionCard/PrescriptionCard";
import { AreaTitle } from "../../components/AreaTitle/AreaTitle";
import { BasicCard } from "../../components/BasicCard/BasicCard";

const Medication = () => {
  const [loading, setLoading] = useState(true);

  const p = usePatient();

  const [data, setData] = useState([]);

  const getDataPrescriptions = useCallback(() => {
    getPrescriptions(p.patient.identification_number)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire(error("Error al obtener información"));
      });
  }, []);

  useEffect(() => {
    getDataPrescriptions();
  }, []);

  return (
    <>
      <BasicCard
        header={
          <AreaTitle
            title="Prescripción de medicamentos"
            icon={<MdIcon.MdMedication />}
            addClass="p-3"
          />
        }
      >
        {loading ? (
          <Loader isActive={loading} />
        ) : (
          <>
            {data && data.length > 0 ? (
              <>
                {data.map((d, i) => {
                  return (
                    <Col xs={12} lg={6} key={i}>
                      <PrescriptionCard prescriptionData={d}></PrescriptionCard>
                    </Col>
                  );
                })}
              </>
            ) : (
              <DataNotFound text="prescripciones de medicamentos"></DataNotFound>
            )}
          </>
        )}
      </BasicCard>
    </>
  );
};

export default Medication;
