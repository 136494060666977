import { HSI_GET_INSTITUTION_PATIENT_ACTIVITIES, HSI_GET_PRESCRIPTIONS } from "../constants/api-hsi.constants";
import { AUTH_HEADER } from "../constants/api.constants";
import { get } from "./httpServices";

export async function getInstitutionPatientActivities(institution_id, patient_dni, dateFrom, dateTo) {
    try {
      const promise = await get(HSI_GET_INSTITUTION_PATIENT_ACTIVITIES(institution_id, patient_dni, dateFrom, dateTo), AUTH_HEADER());
      return promise;
    } catch (err) {
      console.error(err);
    }
  }

  export async function getPrescriptions(patient_dni) {
    try {
      const promise = await get(HSI_GET_PRESCRIPTIONS(patient_dni), AUTH_HEADER());
      return promise;
    } catch (err) {
      console.error(err);
    }
  }