import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import usePatient from "../../hooks/usePatient";
import { vaccinationService } from "../../services/vaccinationService";
import Swal from "sweetalert2";
import { error } from "../../components/SwalAlertData";
import Loader from "../../components/Loader";
import { DataNotFound } from "../../components/DataNotFound/DataNotFound";
import VaccinationCard from "./VaccinationCard";
import * as MdIcon from "react-icons/md";

function CalendarioVacunacion() {

    const [loading, setLoading] = useState(true)
    const [showData, setShowData] = useState([]);
    const p = usePatient();

    const getVaccinationUser = useCallback(() => {

        const bodyRequest = {
            id_tipo_doc: p.patient.identification_number,
            nro_doc: p.patient.id_identification_type,
            sexo: p.patient.id_gender
        }

        vaccinationService(bodyRequest)
        .then((res) => {
            if (res.aplicacionesVacunasCiudadano) {
                setShowData(res.aplicacionesVacunasCiudadano.aplicacionVacunaCiudadano);
            }
        })
        .catch((err) => {
            Swal.fire(error('Error al obtener datos de Nomivac.'));
        })
        .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getVaccinationUser();
  }, []);

    useEffect(() => {
        getVaccinationUser();
    }, [])

    return (
        <Container className='p-3'>
            <Col xs={12} lg={9} className="d-flex">
                <MdIcon.MdOutlineVaccines className="menu-icon text-danger me-1" style={{ fontSize: 'x-large' }} />
                <h5 className='section-title'>Vacunación</h5>
            </Col>
            <h6 className='text-danger'>Vacunas registradas en Nomivac</h6>
            <div>
                {loading ?
                    <Loader isActive={loading}></Loader>
                    :
                    <Row>
                        {showData && showData.length > 0 ?
                            <> {showData.map((vaccinationData, index) =>
                                <Col xs={12} md={8} key={index} className='in'>
                                    <VaccinationCard vaccinationData={vaccinationData} />
                                </Col>
                            )} </>
                            : <DataNotFound text={"vacunación"} />}
                    </Row>}
            </div>
        </Container>
    )
}

export default CalendarioVacunacion;
